import * as Yup from 'yup';

const branchNum = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .integer('Requiere ser entero.')
  .required('Requerido.');

const branchName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Nombre invalido.')
  .trim()
  .required('Requerido.');

const chain = Yup.object().required('Requerido.').nullable();

const isActive = Yup.bool().required();

export const BRANCH_ADD_SCHEMA = Yup.object().shape({
  branchNum,
  branchName,
  chain,
});

export const BRANCH_EDIT_SCHEMA = Yup.object().shape({
  branchNum,
  branchName,
  chain,
  isActive,
});
