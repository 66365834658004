import * as Yup from 'yup';

const invo = Yup.object().nullable();

const zone = Yup.object().nullable();

const plaza = Yup.object().nullable();

const agent = Yup.object().nullable();

const chain = Yup.object().nullable();

const branch = Yup.object().nullable();

const family = Yup.object().required('Requerido.').nullable();

const piece = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo o mayor que 0.')
  .nullable();

const amount = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .required('Requerido.');

export const DISTRIBUTION_ADD_SCHEMA = Yup.object().shape({
  invo,
  zone,
  plaza,
  agent,
  chain,
  branch,
  family,
  piece,
  amount,
});
