import getError from '../getError';
import instance from '../instance';

export const getBranches = async (query) => {
  try {
    const res = await instance.get('/branches', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando sucursales.');
    throw newError;
  }
};

export const createBranch = async (data) => {
  try {
    const res = await instance.post('/branches', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando sucursal.');
    throw newError;
  }
};

export const editBranch = async (data) => {
  try {
    const res = await instance.put('/branches', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando sucursal.');
    throw newError;
  }
};
