import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalConvenienceStoreOutlinedIcon from '@mui/icons-material/LocalConvenienceStoreOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PercentIcon from '@mui/icons-material/Percent';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CableIcon from '@mui/icons-material/Cable';

const links = [
  { roles: ['USER'], link: ['Dashboard', EqualizerOutlinedIcon, '/dashboard'] },
  { roles: ['USER'], link: ['Agregar gasto', PostAddOutlinedIcon, '/agregar'] },
  {
    roles: ['USER', 'ADMIN'],
    link: ['Listado de gastos', GridViewOutlinedIcon, '/gastos'],
  },
  { roles: ['ADMIN'], link: ['Usuarios', PeopleOutlinedIcon, '/usuarios'] },
  {
    roles: ['ADMIN'],
    link: ['Departamentos', AccountTreeOutlinedIcon, '/departamentos'],
  },
  {
    roles: ['ADMIN'],
    link: ['Grupos', ViewListIcon, '/grupos'],
  },
  {
    roles: ['ADMIN'],
    link: ['Conceptos', FormatListBulletedOutlinedIcon, '/conceptos'],
  },
  {
    roles: ['ADMIN'],
    link: ['Proveedores', ShoppingCartOutlinedIcon, '/proveedores'],
  },
  { roles: ['ADMIN'], link: ['Zonas', MapOutlinedIcon, '/zonas'] },
  { roles: ['ADMIN'], link: ['Plazas', LocationOnOutlinedIcon, '/plazas'] },
  {
    roles: ['ADMIN'],
    link: ['Agentes', PersonPinCircleOutlinedIcon, '/agentes'],
  },
  {
    roles: ['ADMIN'],
    link: ['Cadenas', LocalConvenienceStoreOutlinedIcon, '/cadenas'],
  },
  {
    roles: ['ADMIN'],
    link: ['Sucursales', CableIcon, '/sucursales'],
  },
  { roles: ['ADMIN'], link: ['Familias', Inventory2OutlinedIcon, '/familias'] },
  { roles: ['ADMIN'], link: ['Participación', PercentIcon, '/participacion'] },
  { roles: ['ANALYST'], link: ['Exportar', FileDownloadIcon, '/exportar'] },
];

export default links;
